<template>
  <v-dialog v-model="dialog" max-width="290px" transition="dialog-transition">
    <template v-slot:activator="{ attrs, on }">
      <v-text-field
        ref="element"
        dense
        outlined
        :label="label || 'Time'"
        clearable
        v-bind="attrs"
        v-on="on"
        :error-messages="error"
        :value="formattedRangevalue"
        :hide-details="hidedetails"
        :disabled="disabled"
        @click:clear="setBlank(); updateValue()"
      ></v-text-field>
    </template>

    <v-card elevation="0" flat tile>
      <v-card-text class="pa-0">
        <v-time-picker v-model="tempContent" color="primary" scrollable>
          <v-spacer></v-spacer>
          <v-btn text small @click="dialog = false; tempContent = content; updateValue()">Close</v-btn>
          <v-btn small elevation="0" color="primary" @click="content = tempContent; dialog = false; updateValue()">OK</v-btn>
        </v-time-picker>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    content: "",
    tempContent: ""
  }),
  props: {
    error: [String, Array],
    label: String,
    value: String,
    hidedetails: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  methods: {
    setValue() {
      this.content = this.value || "";
    },
    updateValue() {
      this.content = this.tempContent;  // Ensure content updates correctly
      this.$emit("input", this.content);
      this.$emit("change", this.content);
    },
    setFocus() {
      this.$nextTick(() => {
        this.$refs.element?.$el.querySelector("input")?.focus();
      });
    },
    setBlank() {
      this.content = "";
      this.tempContent = "";
    }
  },
  computed: {
    formattedRangevalue() {
      return this.content ? this.$nova.formatTime(this.content) : "";
    }
  },
  watch: {
    value(newVal) {
      this.setValue();
      if (!newVal) this.setBlank();
    }
  },
  created() {
    this.setValue();
  }
};
</script>
