<template>
  <div>
    <!-- Navigation Drawer -->
    <v-navigation-drawer
      v-model="localValue"
      v-if="!dailogBox && !dailogboxOnly"
      right
      :width="Maxwidth"
      class="position-absolute drawer-shadow overflow-hidden"
      scrollable persistent
    >
      <v-card
        class="shadow-off overflow-hidden d-flex flex-column"
        :loading="loading"
        :disabled="loading"
        loader-height="2"
        style="height: 100%;"
      >
        <v-card-title class="align-center py-1 pb-2 px-4">
          <span class="font-weight-semibold text-h6">{{ heading }}</span>
          <v-spacer></v-spacer>
          <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="ml-2">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-2 ma-0 d-flex flex-column align-start radius-medium justify-start all-width">
              <v-list-item class="hoveritem" @click="TodailogBox('side')">
                <v-icon class="px-2">mdi-page-layout-sidebar-left</v-icon>
                Side Peek
              </v-list-item>
              <v-list-item class="hoveritem" @click="TodailogBox('center')">
                <v-icon class="px-2">mdi-page-layout-body</v-icon>
                Center Peek
              </v-list-item>
              <v-list-item class="hoveritem" @click="TodailogBox('full')">
                <v-icon class="px-2">mdi-page-layout-header-footer</v-icon>
                Fullscreen
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn icon @click="CloseDrawer()" class="ml-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4 px-6" style="overflow-y: auto; flex-grow: 1;">
          <slot name="body" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="action-shadow">
          <slot name="actions" />
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>

    <!-- Dialog (Shows First) -->
    <v-dialog v-if="dailogBox" v-model="value" :width="width || 850" scrollable>
      <v-card class="shadow-off" :loading="loading" :disabled="loading" loader-height="2">
        <v-card-title class="white align-center py-2 px-4">
          <span class="font-weight-semibold text-h6">{{ heading }}</span>
          <v-spacer></v-spacer>
          <v-menu v-if="!dailogboxOnly" :close-on-content-click="false" offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="ml-2">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-2 ma-0 d-flex flex-column align-start radius-medium justify-start all-width">
              <v-list-item class="hoveritem" @click="TodailogBox('side')">
                <v-icon class="px-2">mdi-page-layout-sidebar-left</v-icon>
                Side Peek
              </v-list-item>
              <v-list-item class="hoveritem" @click="TodailogBox('center')">
                <v-icon class="px-2">mdi-page-layout-body</v-icon>
                Center Peek
              </v-list-item>
              <v-list-item class="hoveritem" @click="TodailogBox('full')">
                <v-icon class="px-2">mdi-page-layout-header-footer</v-icon>
                Fullscreen
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn icon @click="CloseDrawer()" class="ml-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <slot name="body" />
        </v-card-text>
        <v-card-actions class="py-4 px-6">
          <slot name="actions" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dailogBox: true, // Show the dialog first
      localValue: this.value, // Manage local state per instance
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    Maxwidth: {
      type: String,
      default: "40%",
    },
    dailogboxOnly: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleEscPress);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleEscPress);
  },
  methods: {
    handleEscPress(event) {
      if (event.key === "Escape" || event.key === "Esc") {
        this.CloseDrawer();
      }
    },
    CloseDrawer() {
      console.log("Closing this instance only.");
      this.localValue = false;
      this.$emit("input", this.localValue);
      this.dailogBox = true;
    },
    TodailogBox(peek) {
      if (peek === "center") {
        this.localValue = false;
        setTimeout(() => {
          this.value = true;
          this.dailogBox = true;
        }, 300);
      } else {
        this.dailogBox = false;
        setTimeout(() => {
          if (peek === "side") {
            this.Maxwidth = "40%";
          } else if (peek === "full") {
            this.Maxwidth = "86%";
          }
          this.localValue = true;
        }, 300);
      }
    },
  },
  watch: {
    value(newVal) {
      this.localValue = newVal;
    },
    localValue(newVal) {
      this.$emit("input", newVal);
    },
  },
};
</script>

<style scoped>
.v-navigation-drawer {
  position: fixed !important;
  z-index: 100;
}
.v-dialog {
  height: 50vh;
}
.drawer-shadow {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.action-shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.hoveritem:hover {
  background-color: #6b67ce;
  color: white !important;
}
</style>
